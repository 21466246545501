.header-menu__wrapper {
  position: relative;
}
.header-menu {
  position: absolute;
  margin-top: 16px;
  left: 0;
}
.header-menu__container {
  display: flex;
  flex-direction: column;
  width: 201px;
  z-index: 3;
  background-color: #007e6d;
  color: #ffffff;
  box-shadow: 1px 1px 4px rgba(21, 101, 192, 0.07),
    -1px -1px 4px rgba(39, 101, 202, 0.07);
  box-sizing: border-box;
  position: absolute;
  left: -200px;
  top: 13px;
}

@media (max-width: 991px) {
  .header-menu__container {
    left: -153px;
  }
}
.header-menu__item {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #ffffff !important;
  padding: 10px 1rem !important;
  text-decoration: none;
  cursor: pointer;
}

/* .header-menu__item:not(:first-child) {
  padding: 7px 0;
} */
.triangle {
  position: absolute;
  left: -70px;
  top: 8px;
  z-index: 4;
}

.triangle path {
  fill: #007e6d;
}

.header-menu__item:hover {
  background: #de944c;
}

.modal {
  display: flex !important;
  position: inherit !important;
}
