.signup-agreement {
  display: flex;
  align-items: center;
}
.signup-agreement input {
  margin-right: 10px;
}

input[type='password']::-webkit-contacts-auto-fill-button,
input[type='password']::-webkit-credentials-auto-fill-button {
  visibility: hidden !important;
  display: none !important;
  pointer-events: none !important;
  height: 0 !important;
  width: 0 !important;
  margin: 0 !important;
}

::-ms-reveal {
  display: none !important;
}

.red-error {
  font-size: 12px;
  color: #dc3545;
}
