.avatar__container {
  display: flex;
  justify-content: center;
}

.avatar-crop {
  margin: auto;
  margin-top: 2rem;
}
.width2501 {
  width: 100%;
  max-width: 250px;
}

.avatar__content {
  width: 100%;
  max-width: 250px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.avatar__content > .avatar__wrap-input {
  width: 100%;
}

.avatar__content > .avatar__wrap-input > .avatar__label > .avatar__btn {
  margin-top: 10px;
}

.ReactCrop__image {
  max-height: 300px;
  object-fit: contain;
}

.ReactCrop {
  margin-top: 10px;
}

.avatar__content .esp-filename-label {
  max-width: 500px;
  width: 250px;
  font-size: 60%;
  margin-top: 10px;
  margin-left: -15%;
}

@media only screen and (max-width: 1000px) {
  .avatar__content .esp-filename-label {
    max-width: 500px;
    width: 250px;
    font-size: 60%;
    margin-top: 10px;
    margin-left: -10%;
  }
}
