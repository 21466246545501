.change-email-container {
  min-height: 200px;
  min-width: 500px;
  display: flex;
  width: 40%;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border: var(--bs-border-color-translucent) 1px solid;
  border-radius: 0.5rem;
  position: relative;
  padding: 40px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 15px 10px rgba(0, 0, 0, 0.1);
  align-items: center;
}

.change-email-container > h2 {
  font-size: 18px;
  text-align: center;
}

.change-email-container > a {
  width: 40%;
  margin-top: auto;
  font-size: 1.25rem;
}
