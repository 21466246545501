.register__input {
  background: rgba(39, 101, 202, 0.05);
  border: none;
  border-radius: 3px;
  font-size: 16px;
  line-height: 24px;
  margin-left: 10px;
  padding: 8px 11px;
  width: 332px;
  color: #212121;
  margin-left: 0;
}
.register__input::placeholder {
  color: #909090;
}
.register__error {
  color: #ff0000;
  margin: 13px 0 0px;
}
