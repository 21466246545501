.cabinet-container {
  display: flex;
  margin-top: 43px;
  margin-bottom: 200px;
  position: relative;
}
.cabinet__heading {
  margin-top: 43px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #212121;
}
.cabinet__menu {
  display: flex;
  margin-bottom: 20px;
  margin-left: -15px;
  /*flex-direction: column;*/
}
.cabinet__item {
  font-weight: bold;
  display: block;
  padding: 0.5rem 1rem;
  color: black;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
  text-transform: uppercase;
  letter-spacing: 0.3px;
}
.cabinet__item_active {
  /*background: #fdf5f1;*/
}
.cabinet__item_logout {
  color: #1976d2;
  cursor: pointer;
}
.cabinet-view__container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.cabinet__box {
  display: flex;
  max-width: 577px;
  flex-wrap: wrap;
  justify-content: space-between;
}
.cabinet__box_item {
  width: 280px;
  height: 160px;
  background: #fbfbfb;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 37px;
  text-decoration: none;
  margin-bottom: 15px;
}
.cabinet__box_item-header {
  font-size: 24px;
  line-height: 24px;
  font-weight: bold;

  color: #424242;
}
