.password-change__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 600px;
  margin-bottom: 17px;
}
.password-change__item label {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #212121;
}
.password-change__item input {
  width: 393px;
  border: 1px solid #b7d1ff;
  border-radius: 3px;
  padding: 4px 19px 3px 23px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #212121;
}
.password-change__item input::placeholder {
  color: #c4c4c4;
}

.password-change__error {
  color: #dc3545;
  font-size: 12px;
  margin: 0 0 13px;
}
