.general-info__container {
  display: flex;
  flex-direction: column;
  position: relative;
}
.general-info__avatar {
  display: flex;
  flex-direction: column;
  width: 114px;
  height: 151px;
  cursor: pointer;
  background: rgba(244, 244, 244, 0.5);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #909090;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
}
.general-info__table th {
  text-align: left;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #212121;
}
.general-info__table th {
  padding-top: 17px;
}
.general-info__table td input {
  width: 100%;
}
.general-info__table tr:first-child th {
  padding-top: 0;
}
.general-info__table tr:nth-child(-n + 4) td input {
  width: 60%;
}
.general-info__input,
.email-change-modal-content > input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 12px !important;
  font-weight: 400;
  line-height: 1.25;
  color: #000000 !important;
  background-color: rgba(244, 244, 244, 0.5) !important;
  background-clip: padding-box;
  border: none;
  appearance: none;
  border-radius: 10px !important;
}
.general-info__input__date {
  border: 1px solid #b7d1ff;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 0 17px 0 23px;
  height: 30px;
}
.general-info__input__date .react-date-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: none;
}
.general-info__input__checkbox {
  height: 16px;
  width: 16px !important;
}
.general-info__input__select {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 12px !important;
  font-weight: 400;
  line-height: 1.25;
  color: #000000 !important;
  background-color: rgba(244, 244, 244, 0.5) !important;
  background-clip: padding-box;
  border: none;
  appearance: none;
  border-radius: 10px !important;
}
.general-info__input__radio {
  height: 13px;
  width: 13px !important;
}
.general-info__input__radio-label {
  margin-left: 13px;
  font-size: 16px;
}
.general-info__input__radio-container {
  margin-left: 38px;
}
.general-info__input__radio-container:first-child {
  margin-left: 0;
}
.avatar-modal,
.email-change-modal {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  z-index: 999;
}
.avatar-modal > div {
  position: relative;
  background: rgb(255, 255, 255);
  margin: auto;
  border: 1px solid rgb(187, 187, 187);
  padding: 15px;
  border-radius: 0.3rem;
  max-width: 500px;
  width: 100% !important;
  max-height: 600px;
  overflow-y: auto;
}

.email-change-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  gap: 1rem;
  position: relative;
  background: rgb(255, 255, 255);
  margin: auto;
  border: 1px solid rgb(187, 187, 187);
  padding: 15px;
  border-radius: 0.3rem;
  overflow-y: auto;
  min-width: 300px;
  min-height: 200px;
  width: 35%;
}

.email-change-modal-content > label {
  font-size: 18px;
  font-weight: 500;
}

.email-change-modal-content > input {
  width: 60%;
}

.email-change-show-btn {
  border: none;
  outline: none;
  background: none;
  margin-top: 10px;
  font-size: 12px;
  color: #000000;
  text-decoration: none;
  padding-left: 0;
}

.email-change-show-btn:hover {
  color: #007e6d;
}

.email-change-modal-content-btn {
  width: 70%;
  justify-content: center;
  display: flex;
  gap: 1rem;
}

.email-change-modal-content-btn > button {
  font-size: 12px;
  width: 40%;
}

.general-info__btn__link {
  font-size: 0.75rem;
  font-style: italic;
  margin-top: 0.5rem;
  color: blue;
  background: none;
  outline: none;
  border: none;
  transition: all ease-in-out 0.1s;
}

.general-info__btn__link:hover {
  color: orange;
}

.general-info__change-email__valid-err {
  font-size: 12px;
  color: #dc3545;
}

@media (max-width: 1220px) {
  .email-change-modal-content > input {
    width: 100%;
  }

  .email-change-modal-content-btn {
    width: 100%;
  }
}
