.comp__header {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  margin-top: 43px;
}

.comp__header--h1 {
  position: absolute;
  top: 0;
}

.comp__info {
  display: inline-flex;
  color: #a3a3a3;
  margin-top: 15px;
  font-size: 17px;
}
.comp__date {
  margin-left: 15px;
  color: #212121;
}
.comp__end {
  margin-left: 20px;
}
.comp__status {
  color: #a3a3a3;
  font-size: 17px;
}
.comp__status span {
  color: #212121;
}
.comp__info__text {
  font-size: 18px;
}
.comp__requirements__wrapper {
  display: flex;
  justify-content: space-between;
}
/* .comp__requirements__left {
  width: 49%;
} */
.comp__requirements__right {
  width: 49%;
}
.comp__requirements__header {
  margin-top: 43px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #212121;
}
.comp__requirements__text {
  margin-top: 13px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #212121;
}
.comp__requirements__container {
  margin-top: 23px;
  background: #fdf5f1;
  border-radius: 4px;
  padding: 23px 13px;
}
.comp__requirements__item {
  display: flex;
  margin-bottom: 23px;
}
.comp__requirements__number {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #212121;
}
.comp__requirements__item:last-child {
  margin: 0;
}
.comp__requirements__desc {
  margin-left: 18px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.comp__requirements__mandatory {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #ff0000;
  margin-left: 4px;
}
.comp__requirements__content {
  display: flex;
  justify-content: space-between;
}
.comp__docs__header {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #212121;
  margin-top: 43px;
}
.comp__docs__container {
  display: inline-flex;
  flex-direction: column;
  margin-top: 44px;
  border-radius: 4px;
  width: 100%;
  padding: 23px 13px;
  background: #fdf5f1;
}
.comp__docs__container tr {
  width: 100%;
  height: 30px;
}
.comp__docs__container tbody tr:last-child td {
  padding-bottom: 0;
}
.comp__docs__container td {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #212121;
  vertical-align: middle;
  text-align: left;
  padding-bottom: 23px;
}

.comp__docs__container .comp__docs__item {
  margin-bottom: 10px;
  border-bottom: 1px solid #f1f1f1;
}

.comp__docs__item p {
  color: #a3a3a3;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
}
.comp__docs__item span {
  color: #212121;
  margin-left: 5px;
}

.comp__attachment__container {
  display: inline-flex;
  margin-top: 15px;
  width: 100%;
}
.comp__attachments__header {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #212121;
  margin: 23px 0;
}
.comp__programs__container {
  display: inline-flex;
  flex-direction: column;
  margin-top: 15px;
  width: 100%;
}
.comp__programs__item {
  margin-bottom: 10px;
  border-bottom: 1px solid #f1f1f1;
  background: #f7f6f7;
  border-radius: 8px;
  padding: 30px 30px 20px;
  width: 300px;
}
.comp-results {
  display: flex;
  flex-direction: column;
}
.comp-results__heading {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #ff0000;
  margin-top: 43px;
}
.comp-results__text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
  margin: 23px 0;
}

.comp-result__file {
  margin-top: 13px;
}

.comp__scorings {
  display: flex;
  flex-direction: column;
  margin-top: 19px;
}
.comp__scorings__container {
  display: flex;
  background-color: #fdf5f1;
  border-radius: 4px;
  padding: 14px 10px 14px;
  margin-top: 23px;
}
.comp__scorings__table {
  width: 100%;
}
.comp__scorings__table td {
  padding: 12px 0;
}
.comp__scorings__table td:first-child {
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #909090;
}
.comp__scorings__table td:nth-child(2) {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
  border-right: 1px solid #d6dade;
}
.comp__scorings__table td:last-child {
  text-align: right;
  padding-right: 14px;
}

.comp__scorings__table tr:not(:last-child) {
  border-bottom: 1px solid #d6dade;
}
.comp__scorings__table tr:first-child {
  padding-top: 0;
}
#asd {
  height: 43px;
  margin-left: 10px;
}
.add-to-calendar-dropdown {
  color: #212121;
}
.competition-loading__modal {
  position: fixed; /* Stay in place */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
.esp__file {
  width: 577px;
  display: flex;
  flex-direction: column;
  margin-bottom: 17px;
}
.esp__file > input {
  width: 350px;
}
.esp__password {
  display: flex;
  flex-direction: column;
  margin-bottom: 17px;
}
.esp_password__label {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #212121;
  margin-bottom: 10px;
}
.esp__password__input {
  font-size: 16px;
  line-height: 24px;
  padding: 6px 15px;
  color: #212121;
  border: 2px solid #b7d1ff;
  border-radius: 3px;
  width: 220px;
}
