.breadcrumps__container {
  display: flex;
  align-items: center;
}

.breadcrumps__arrow {
  margin: 0 10px;
}

.breadcrumps__item:not(:last-child) {
  display: flex;
  position: relative;
  /*padding-right: 15px;*/
}

.breadcrumps__link {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /*color: #209898;*/
  color: #000000;
  text-decoration: none;
  padding-right: 3px;
}

.breadcrumps__link:hover,
.breadcrumps__link:active {
  color: #209898;
}

/*.breadcrumps__link:after {*/
/*    position: relative;*/
/*    content: '/';*/
/*    padding: 0 2px;*/
/*}*/
