.tm-th {
  width: 40% !important;
  text-align: left !important;
}
.tm-td {
  text-align: left !important;
}

.fade3s {
  animation: fade 7s;
  transition: 0.5s ease-in;
}

.application-table__body_tm {
  background-color: #fff;
}

.application-table-tm {
  margin-bottom: 2rem;
  background: rgba(244, 244, 244, 0.5);
  display: flex;
  flex-direction: column;
}

.tm-delete-btn {
  align-self: end;
  border: none !important;
}

@keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
