.wrapper {
  max-width: 1000px;
}

.portal__content {
  padding: 0 !important;
}

.attachment__section {
  display: flex;
}

.attachment_file {
  font-weight: bold;
  font-size: 12px;
  margin-left: 5px;
}

.application_status {
  font-size: 14px;
  font-weight: bold;
  color: #007e6d;
}

.attachment_file__pdf {
  font-size: 12px;
  font-weight: bold;
  padding: 0;
  margin: 0;
}

li::marker {
  color: #de944c;
}

#withdraw-margin {
  margin: 5px 0px 10px 0px;
}

.portal__card__home {
  position: relative;
  padding: 40px;
  background-color: #fff;
  border: 1px solid #e8e1d9;
  border-radius: 10px;
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  .portal__card__home {
    margin-right: 40px;
    padding: 20px;
    margin-bottom: 20px;
  }
}

@media (max-width: 575px) {
  .portal__card__home {
    margin: 0 0 20px 0;
  }

  .portal__card--content {
    margin: 0 20px 20px;
  }
}
