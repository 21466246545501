.sign-in__wrapper {
  display: flex;
  justify-content: center;
}
.sign-in__container {
  display: flex;
  flex-direction: column;
  margin-top: 103px;
}
.sign-in__header {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #424242;
}
.sign-in__panel {
  margin-top: 27px;
  border-radius: 4px;
  display: inline-flex;
  flex-direction: column;
}
.sign-in__login {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sign-in__password {
  margin-top: 23px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.sign-in__button {
  background: #e97036;
  border-radius: 3px;
  padding: 12px 0;
  color: white;
  border: none;
  margin-top: 13px;
  font-size: 18px;
  line-height: 21px;
  width: 100%;
}
.sign-up__link {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #1565c0;
  text-decoration: none;
  margin-top: 23px;
}
.forgot-password__link {
  display: block;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  color: #000000;
  text-decoration: none;
  /*position: absolute;*/
  /*right: -126px;*/
}
.sign-in__label {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
}
.sign-in__input {
  border: 1px solid #b7d1ff;
  border-radius: 3px;
  font-size: 16px;
  line-height: 24px;
  margin-left: 10px;
  padding: 8px 11px;
  width: 267px;
  color: #212121;
}
.sign-in__input::placeholder {
  color: #909090;
}
.sign-in__input_login {
  margin-left: 20px;
}

.sign-in__error {
  color: #ff0000;
  /*margin: 13px 0 0 66px;*/
}
