html {
}

html,
body {
  height: 100%;
  width: 100%;
  font-family: 'Roboto', sans-serif !important;
}

html::-webkit-scrollbar {
  /*display: none;*/
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

html {
  -ms-overflow-style: none;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  /*padding-top: 123px;*/
  /*position: absolute;*/
  margin: auto;
}

.flex {
  display: flex;
}

.space-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.m-l-10 {
  margin-left: 10px;
}

.m-t-20 {
  margin-top: 20px;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-x-10 {
  margin: 0 10px;
}

.w-70 {
  width: 70%;
}

.deny-button {
  border: none;
  background: none;
  color: #0347c2;
  font-weight: bold;
}

.apply-button {
  background: #3770d4;
  border-radius: 3px;
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  width: 215px;
  padding: 12px 0;
  border: none;
  text-decoration: none;
  text-align: center;
}

.apply-button:disabled {
  opacity: 0.5;
}

.results-button {
  background: #ed7d31;
  border-radius: 3px;
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  width: 215px;
  padding: 12px 0;
  border: none;
  text-decoration: none;
  text-align: center;
}

.cancel-button {
  background: #ffffff;
  border-radius: 4px;
  color: #212121;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  padding: 11px 17px;
  border: 1px solid #e97036;
  text-decoration: none;
}

.page-title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #212121;
  margin-top: 43px;
}

.breakline {
  width: 97%;
  border-bottom: 1px solid #d6dade;
  margin: 23px auto 0;
}

.modal {
  font-size: 18px;
  display: flex;
  flex-direction: column;
  padding: 40px;
}

.modal__header.modal__header--policy {
  text-align: left;
  padding-left: 0;
  padding-right: 0;
}

.modal__header {
  width: 100%;
  font-size: 25px;
}

.modal__header h1 {
  font-size: 18px;
  font-weight: 500;
}

@media (max-width: 991px) {
  .modal__header h1 {
    font-size: 16px;
    font-weight: 500;
  }
}

.modal__content {
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.4em;
  color: #000000;
}

.modal__actions {
  display: flex;
  margin-top: 53px;
}

.modal__actions button:first-child {
  margin-right: 23px;
}

.popup-content {
  /*width: auto !important;*/
  padding: 0 !important;
}

.file-input__container {
  display: inline-flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.file-upload {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /*-webkit-appearance: none;*/
  /*margin: 0;*/
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.mandatory {
  color: #ff0000;
}

.limit-label {
  font-size: 10px;
  margin-top: -20px;
  text-align: left;
}

/*===new_design===*/
.title__cabinet {
  color: #007e6d;
  font-size: 38px;
  font-weight: 700;
  margin: 60px 0 20px 0;
}

@media (max-width: 575px) {
  .title__cabinet {
    font-size: 24px;
    /*text-align: center;*/
    margin: 60px 20px 10px 20px;
  }
}

.title__home {
  font-size: 38px;
  color: #007e6d;
  font-weight: 700;
  margin: 60px 0 40px 0;
}

@media (max-width: 768px) {
  .title__home {
    margin: 60px 0 10px 0;
    font-size: 24px;
    /*text-align: center;*/
  }
}

@media (max-width: 575px) {
  .title__home {
    margin: 60px 20px 10px 20px;
  }
}

.status__tab {
  display: flex;
  margin-top: 50px;
}

.status__tab1 {
  width: 26%;
}

@media (max-width: 768px) {
  .status__tab1 {
    width: 100%;
    margin-right: 10px;
  }
}

.status__tab2 {
  width: 74%;
}

@media (max-width: 768px) {
  .status__tab2 {
    width: 100%;
    margin-left: 10px;
  }
}

.portal__text {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 0;
}

.portal__text--header {
  display: none;
  margin-bottom: 10px;
}

@media (max-width: 575px) {
  .portal__text {
    font-size: 12px;
  }
}

.portal__pi__text {
  font-size: 12px;
}

@media (max-width: 575px) {
  .portal__pi__text {
    font-size: 12px;
  }
}

.compform__divider {
  margin: 20px 0 20px 0;
  height: 1px;
  width: 100%;
  border-top: 1px solid #a8a8a8;
}

.container {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.comp__program__form {
  width: 100%;
}

.input__header {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 10px;
}

.home__content {
  margin-top: 10px;
}

@media (max-width: 768px) {
  .home__content {
    width: 100%;
  }
}

.default-input,
.react-datepicker-wrapper input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.25;
  color: #000000 !important;
  background-color: rgba(244, 244, 244, 0.5) !important;
  background-clip: padding-box;
  border: 1px solid transparent;
  appearance: none;
  border-radius: 10px;
  min-height: 36px;
}

.default-input.input__error,
.text-area.input__error,
.project-duration__error,
.red-border-error {
  border: 1px solid red !important;
}

.project-duration__error {
  border-radius: 10px;
}

.tm-error {
  padding: 5px;
  border-radius: 10px;
  margin-top: 5px;
}

.text-area:focus:not([readonly]),
.default-input:focus:not([readonly]) {
  border: 1px solid #de944c;
}

.field-radio {
  display: flex;
  position: relative;
  align-items: center;
  margin-top: 20px !important;
}

.field-radio__btn {
  display: flex;
  margin-left: 0 !important;
}

@media (max-width: 768px) {
  .field-radio {
    flex-direction: column;
    align-items: flex-start;
  }
}

.td-radio.radio__tb1.field-radio__btn--group {
  padding-left: 0;
}

.field-radio__btn--group {
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
  display: inline-block;
  margin-right: 1rem;
}

.field-radio__btn--group input[type='radio'] {
  display: none;
}

.field-radio__btn--group label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 25px;
  margin-right: 0;
  line-height: 18px;
  user-select: none;
}

.field-radio__btn--group label:before {
  content: '';
  display: inline-block;
  width: 17px;
  height: 18px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 1px;
  border: 1px solid rgba(0, 0, 0, 0.25);
}

.field-radio__btn--group input[type='radio']:checked + label:before {
  background-color: #007e6d;
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27M6 10l3 3l6-6%27/%3e%3c/svg%3e') !important;
}

.field-radio__btn--group label:hover:before {
  filter: brightness(120%);
}

.field-radio__btn--group input[type='radio']:disabled + label:before {
  filter: grayscale(100%);
}

.default-input--select {
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e');
}

.main__question {
  margin-top: 20px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  color: #007e6d;
}

.form__question {
  margin-top: 20px;
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: bold;
}

.form__question__descrption {
  font-size: 12px;
  line-height: 1.25;
}

.text-area {
  padding: 15px;
  display: block;
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.25;
  color: #000000 !important;
  background-color: rgba(244, 244, 244, 0.5) !important;
  background-clip: padding-box;
  border: 1px solid transparent;
  appearance: none;
  border-radius: 10px;
  resize: vertical;
  margin-bottom: 30px;
}

.second__table {
  width: 100%;
}

.application-table th {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #212121;
  border: 1px solid #c6c6c6;
  border-radius: 10px;
  padding: 15px;
  vertical-align: middle;
  white-space: nowrap;
}

.application-table__body {
  position: relative;
  width: 100%;
  max-width: 100%;
  background: rgba(244, 244, 244, 0.5);
  border-radius: 10px;
  overflow-x: auto;
}

.table-calendar {
  overflow: initial !important;
}

.table-calendar th {
  white-space: break-spaces !important;
}

.application-table {
  width: 100%;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 20px;
}

.first__table-title {
  text-align: left;
  font-size: 14px;
  border-bottom-color: #000000;
}

#width-60 {
  width: 60%;
}

#width-40 {
  width: 40%;
}

#width-20 {
  width: 20%;
}

#width-25 {
  width: 25%;
}

#width-30 {
  width: 30%;
}

#width-15 {
  width: 15%;
}

#width-25 {
  width: 25%;
}

#width-70 {
  width: 70%;
}

.expense-plan__td {
  border: 1px solid #c6c6c6;
  border-radius: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #212121;
  vertical-align: middle;
  white-space: nowrap;
}

.expense-plan__input {
  font-size: 12px;
  line-height: 19px;
  border: none;
  min-height: 40px;
  width: 100%;
  background: rgba(244, 244, 244, 0.5);
  padding: 15px;
}

.expense-plan__td .expense-plan__input--finance {
  padding-left: 0;
}

.form__question__descrption-p {
  font-size: 12px;
  line-height: 1.2em;
}

.expense-plan__input--finance {
  background: #ffffff;
}

.finance-table td {
  text-align: left;
  padding-left: 15px;
}

.finance-table tr {
  border: 1px solid #c6c6c6;
}

.inputfile-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 5px;
}

.inputfile-wrap a {
  padding: 5px;
}

.inputfile-wrap input {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.inputfile-wrap label {
  display: flex;
  align-items: center;
  justify-content: center;
}
.inputfile-wrap label.disabled .btn-success {
  opacity: 0.5;
}

.button-input {
  border-radius: 10px !important;
  border-color: #007e6d !important;
  outline: 0 none !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  text-transform: uppercase;
  padding: 10px 15px 10px;
  margin-top: 20px;
}

.btn-save {
  color: #fff !important;
  background-color: #de944c !important;
  border-color: #ffffff !important;
}

.btn-success:hover,
.button-input:hover,
.btn-save:hover {
  background-color: #000000 !important;
}

.application-form__wrap {
  display: flex;
}

.application-form__wrap button:first-child {
  margin-right: 1.5rem;
}

.expense-plan__td--icon {
  border: none;
  padding-left: 5px;
}

.input-tabs {
  /*margin-top: -23px;*/
}

.input-tabs__wrap {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
}

.input-tabs__label {
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.input-tabs__label:hover {
  color: #de944c;
  transition: 0.2s ease-in;
}

.input-tabs__name input[type='checkbox']:checked + label {
  color: #de944c;
}

.input-tabs__name {
  margin-right: 20px;
}

.input-tabs__input {
  visibility: hidden;
}

.comp__program__info__box {
  padding: 20px;
  background-color: rgba(244, 244, 244, 0.5);
  border: 0 solid rgba(244, 244, 244, 0.5);
  border-radius: 10px;
}

.h-divider {
  height: 1px;
  width: 100%;
  border-top: 1px solid #a8a8a8;
}

.tap_divider {
  margin: 10px 0 20px 0;
  border-top: 1px solid #dee2e6 !important;
}

.comp__program__info {
  margin: auto;
  font-size: 12px;
}

.portal__card--wrap {
  margin-top: 50px;
}

.page-wrapper__bg {
  background-image: url('./../../assets/images/sign-background.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 100vh;
}

.page-wrapper__bgc {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
}

.login__card__home {
  position: relative;
  padding: 40px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 15px 10px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .login__card__home {
    margin: 0 10px 0 10px;
    padding: 20px;
  }
}

@media (max-width: 450px) {
  .table-calendar th {
    word-break: break-all;
  }
}

.accordion-body {
  padding: 10px 0 !important;
}

.sign-in__logo {
  width: 100px;
  margin-bottom: 40px;
}

.form-input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.25;
  color: #000000;
  background-color: rgba(244, 244, 244, 0.5) !important;
  background-clip: padding-box;
  border: none;
  appearance: none;
  border-radius: 10px;
  min-height: 36px;
}

.general-info__input__select:focus-visible,
.text-area:focus-visible,
.expense-plan__input:active,
.expense-plan__input:focus,
.expense-plan__input:focus-visible,
.default-input:active,
.default-input:focus,
.default-input:focus-visible,
.general-info__input:active,
.general-info__input:focus,
.general-info__input:focus-visible,
.form-input:active,
.form-input:focus,
.form-input:focus-visible {
  outline: none;
}

.border-input:focus {
  border: 1px solid #de944c;
}

.form-button {
  border-radius: 10px !important;
  border-color: #007e6d !important;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset,
    0 0 8px rgba(126, 239, 104, 0) !important;
  outline: 0 none !important;
  font-size: 12px !important;
  font-weight: 700;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  padding: 10px 15px 10px;
  width: 100%;
}

.form-header {
  color: #007e6d;
  font-size: 24px;
  margin-bottom: 20px;
  letter-spacing: 0;
}

.portal__title {
  text-align: right;
  font-weight: bold;
  font-size: 60px;
  color: #ffffff;
  line-height: 1.2em;
}

@media (max-width: 768px) {
  .portal__title {
    text-align: center;
    font-size: 32px;
    line-height: 1.25em;
    padding: 0 10px 0 10px;
    margin: 60px 0 40px 0;
  }
}

.accordion-item {
  border: none !important;
  padding: 0;
}

.title {
  font-weight: bold;
  font-size: 14px;
}

.accordion-button {
  margin: 0;
  padding: 0 !important;
  outline: 0 !important;
  border-color: #ffffff !important;
  cursor: pointer;
  color: #000000 !important;
}

.accordion-button:active,
.accordion-button:hover,
.accordion-button:focus {
  outline: 0 !important;
  box-shadow: none !important;
  border-color: #ffffff !important;
}

.accordion-button:not(.collapsed) {
  outline: none !important;
  background-color: #ffffff !important;
  border-color: #ffffff !important;
}

.accordion-button:not(.collapsed)::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%230c63e4%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e');
  color: black;
  transform: rotate(-180deg);
  background-position-y: 10px;
}

.accordion-button::after {
  flex-shrink: 0;
  width: 10px !important;
  margin-right: 0;
  margin-bottom: 6px;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAMAAAD04JH5AAAAA3NCSVQICAjb4U/gAAAACXBIWXMAAAOaAAADmgHumOXVAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAP9QTFRF////AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAfOnhEQAAAFR0Uk5TAAUGCAkMDQ4PFBYXGSAiKC4yMzQ1Njg8PT4/QEFCSkxNU1VWZWx1fYCBhouMkpWWn6Kmp6iyt7m6vL3ExcfI0tPV2t/g5+nq7u/w8fLz9PX6/P3+u6bzrAAAAT5JREFUeNrtztk6QgEAxPHhnCIiUvYla9n3PYWyhzDv/ywu+rijq5rzfWfmAeb3B9puZLvcuNlfhmg9pSZJksdpiR9e8Gd3GYV/TioLgjNSWRCcktKCQ1JasEhKC4J7agvGSW3BKsUFRYoLFiguGPxSF+xQXJCoqwvyzy5wgQtc4ILoFORc4AIAuScXuCAKBWMucIELXBCZgqwLkH10gQtc4ILWRl3ggjYFt6G6YAvigs+0uqAAcUGpOwETr38FFMU+58V+c0Drc70L/uQ//nWv1q8P2bffYf/NvtKfsm/ffmz9afv27dsX+TPv9u3bj6s/a9++ffsx9VMPWh8HYn9Y7GNJ7GND7GNN7GNO7KP/ResDK2IfOBH7SFxqfSBZ1vpA8krrA32VX7+m8IFw86PlH6UgWn630qjuFTr0/g3DHj7d53+cVAAAAABJRU5ErkJggg==') !important;
  background-size: 10px !important;
}

.back__home__reg {
  font-size: 12px;
  margin-top: 10px;
  color: #000000;
  text-decoration: none;
}

.remeber__me:hover,
.back__home__reg:hover {
  color: #007e6d;
}

@media (max-width: 768px) {
  .back__home__reg {
    font-size: 10px;
  }
}

.form-sign_up {
  max-width: 330px;
  width: 100%;
  margin: 50px auto 50px;
}

.remeber__me__flex {
  display: flex;
}

.remeber__me {
  font-size: 12px;
  margin: 0 0 10px 5px;
  cursor: pointer;
}

/* ============== */
.sign-up__checkbox input[type='checkbox'] {
  display: none;
}

.sign-up__checkbox label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 25px;
  margin-right: 0;
  line-height: 18px;
  user-select: none;
}

.sign-up__checkbox label:before {
  content: '';
  display: inline-block;
  width: 17px;
  height: 18px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 1px;
  border: 1px solid rgba(0, 0, 0, 0.25);
}

/* Checked */
.sign-up__checkbox input[type='checkbox']:checked + label:before {
  background-color: #007e6d;
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27M6 10l3 3l6-6%27/%3e%3c/svg%3e') !important;
}

/* Hover */
.sign-up__checkbox label:hover:before {
  filter: brightness(120%);
}

/* Disabled */
.sign-up__checkbox input[type='checkbox']:disabled + label:before {
  filter: grayscale(100%);
}

.form-bg {
  background-color: rgba(0, 0, 0, 0.3);
  height: 100%;
  position: fixed;
  width: 100%;
  top: 0;
  left: 1px;
  overflow-y: auto;
  display: flex;
}

.body__p {
  color: #424242;
  font-size: 14px;
  line-height: 1.3em;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .body__p {
    font-size: 12px;
  }
}

.application-view {
  flex-direction: column;

  max-width: 650px;
  min-width: 275px;
  margin-top: 10px !important;
}

@media (min-width: 769px) {
  .application-view {
    margin-top: 30px !important;
    min-height: 530px;
  }
}

.application-view:hover {
  box-shadow: 0 5px 10px 0 rgb(0 0 0 / 5%);
}

.complete-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.button-deny {
  border-radius: 10px !important;
  background-color: #ebebea !important;
  border: 0 solid #fff !important;
  outline: 0 none !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  text-transform: uppercase;
  margin: 20px 0 0 0;
  padding: 10px 15px 10px !important;
  transition: 0.3s opacity;
}

.button-deny:hover {
  transition: 0.3s ease-out;
}

.comp__description {
  font-size: 14px;
  margin-bottom: 10px;
  display: none;
}

.personal-form__footer.btn-cabinet {
  margin: 0;
}

@media (max-width: 768px) {
  .comp__description {
    font-size: 12px;
  }
}

.btn-deny {
  width: max-content;
}

/*cabinet*/
.cabinet__card {
  position: relative;
  padding: 40px;
  background-color: #fff;
  border: 1px solid #e8e1d9;
  border-radius: 10px;
  width: 100%;
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  .cabinet__card {
    padding: 20px;
    margin: 0 0 20px;
  }
}

@media (max-width: 575px) {
  .cabinet__card {
    margin: 0 20px 20px;
    width: auto;
  }

  .application-form__wrap {
    flex-direction: column;
  }

  .application-form__wrap button {
    width: 100%;
    align-items: center;
    max-width: 200px;
  }
}

.general-info__table {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 30px;
}

.cabinet-label {
  /*display: flex;*/
  /*flex-direction: column;*/
  /*width: 100%;*/
  /*margin: 0 20px;*/
  /*max-width: 370px;*/
}

.title__cabinet__form {
  font-size: 16px;
  font-weight: bold;
  color: #000;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .title__cabinet__form {
    margin-top: 10px;
  }
}

.cabinet-radio {
  display: flex;
  align-items: center;
  margin: 0;
  width: 100%;
  max-width: 370px;
}

.cabinet-radio span {
  margin-bottom: 3px;
}

.cabinet-radio .work {
  margin-left: 10px;
}

.col.my-application {
  max-width: 300px;
  width: 100%;
  margin: 20px 20px 20px 0;
  flex-basis: 20%;
}

.sign-in__login.sign-in__login--password {
  flex-direction: column;
  align-items: flex-start;
}

.password-change__container {
  max-width: 320px;
  min-width: 210px;
  width: 100%;
  padding: 20px 0 20px 0;
}

.login__card--sign-in {
  max-width: 330px;
  width: 100%;
  margin-right: auto;
}

@media (max-width: 768px) {
  .login__card--sign-in {
    margin: 0 auto 30px;
  }
}

.cabinet__item:hover {
  color: #de944c;
}

.cabinet__item.cabinet__item_active {
  color: #de944c;
}

.modal-title {
  font-size: 14px;
  font-weight: bold;
  color: #000;
  text-align: center;
  width: 100%;
  line-height: 1.25;
  padding: 1rem 1rem;
}

.modal-content {
  border: 0 solid #fff !important;
}

.popup-content {
  border-radius: 0.3rem;
  max-width: 500px;
  width: 100% !important;
}

.btn__actions {
  text-align: center;
}

.btn__actions button {
  margin-left: 10px;
  margin-right: 10px;
}

.column__left {
  margin-right: 30px;
}

.column__right {
  margin-left: 30px;
}

@media (max-width: 768px) {
  .column {
    margin: auto !important;
    width: 100%;
  }

  .column__left {
    margin-right: 0;
  }

  .column__right {
    margin-left: 0;
  }
}

.avatar__input {
  display: none;
}

.avatar__label {
  position: relative;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  color: #000;
  width: 100%;
  line-height: 1.25;
  margin: 1rem 1rem;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.btn__actions--avatar {
  display: flex;
  flex-direction: column;
  max-width: 250px;
  width: 100%;
  margin: auto;
}

.avatar__container {
  display: flex;
  flex-direction: column;
}

.avatar__btn {
  margin-right: 10px;
  margin-left: 10px;
}

.avatar__label--cabinet {
  margin: 0;
}

.avatar__img.avatar__img--cabinet {
  margin: 10px auto 0;
}

.esp__label {
  position: relative;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  color: #000;
  line-height: 1.25;
  margin-bottom: 20px;
}

.esp__content {
  text-align: center;
}

.avatar__img.avatar__img--ecp {
  margin-top: 20px;
}

@media (max-width: 991px) {
  .esp__label,
  .avatar__label {
    font-size: 16px;
  }
}

.avatar__img {
  width: 60px;
  margin: auto;
  display: block;
}

/*.avatar__label:after {*/
/*    content: url('./../../assets/images/camera.png');*/
/*    position: absolute;*/
/*    width: 30%;*/
/*    height: 50px;*/
/*    left: 45px;*/
/*    top: 30px;*/
/*}*/

button {
  box-shadow: none !important;
}

.btn-success {
  background-color: #007e6d !important;
}

.btn-success:hover {
  background-color: #000000 !important;
}

.btn-light:hover {
  background-color: #000000 !important;
  color: #ffffff !important;
}

.header__username--name {
  font-weight: bold;
}

@media (max-width: 991px) {
  .container.container__nav {
    max-width: 100%;
  }

  .container__nav .header__divider {
    border: none;
  }

  .img__header {
    margin-left: 20px;
  }

  .page-wrapper__mob,
  .page-wrapper__bg {
    margin-top: 122px;
  }
}

.general-info__table.cabinet-data {
  align-items: baseline;
}

.home__cabinet {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 20px;
  max-width: 370px;
  min-width: 270px;
}

@media (max-width: 991px) {
  .home__cabinet {
    margin: 0;
  }
}

@media (max-width: 1400px) {
  .home__cabinet:nth-child(3) {
    margin: 0;
  }
}

.home__cabinet:first-child {
  margin-left: 0;
}

.input__header--general-info {
  display: block;
}

.input__wrapper {
  position: relative;
  width: 100%;
}

/* TODO remove temp style width250*/
.width250 {
  margin: 0 10px;
  width: auto !important;
}

.input__password-icon {
  position: absolute;
  right: 10px;
  bottom: 50%;
  transform: translate(0, 50%);
  cursor: pointer;
}

.loader-image {
  width: 86.8px;
  position: absolute;
  z-index: -1;
}

.button-input__document {
  margin-top: 0;
}

.page-wrapper.page-wrapper__bg {
  margin-top: 70px;
}

@media (min-width: 992px) {
  .page-wrapper.page-wrapper__bg {
    margin-top: 0;
  }

  .page-wrapper__bgc {
    padding: 130px 0;
  }
}

.td-radio__label {
  font-size: 14px;
}

.spinner {
  position: relative;
  width: 16px;
  height: 16px;
  margin: 24px auto;
}

.spinner::before,
.spinner::after {
  content: '';
  width: 100%;
  height: 100%;
  display: block;
  animation: spinner-b4c8mmsm 0.5s backwards,
    spinner-49opz7sm 1.25s 0.5s infinite ease;
  border: 4px solid #007e6d;
  border-radius: 50%;
  box-shadow: 0 -24px 0 -4px #007e6d;
  position: absolute;
}

.spinner::after {
  animation-delay: 0s, 1.25s;
}

@keyframes spinner-b4c8mmsm {
  from {
    box-shadow: 0 0 0 -4px #007e6d;
  }
}

@keyframes spinner-49opz7sm {
  to {
    transform: rotate(360deg);
  }
}

.react-datepicker__triangle {
  left: -50px !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__day--in-range,
.react-datepicker__year-text--keyboard-selected {
  background: #de944c !important;
}

.react-datepicker__day--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ) {
  background: #de944c8f !important;
}

.react-datepicker__header {
  background: #007e6d !important;
}

.react-datepicker__day-name,
.react-datepicker__current-month {
  color: #ffffff !important;
}

.react-datepicker-popper[data-placement^='bottom'],
.react-datepicker__triangle::after {
  border-bottom-color: #007e6d !important;
}

@media (max-width: 767px) {
  .radio-form:first-child {
    padding-left: 0;
  }
}

.react-datepicker__current-month {
  text-transform: capitalize;
}

.weight-normal {
  font-weight: normal;
}

.general-info__photo {
  font-size: 14px;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
}

.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none !important;
}

.budget-header {
  white-space: break-spaces !important;
}

.column.column__competiotions {
  width: 100%;
  margin: auto;
}

.budget-result {
  font-weight: bold;
  font-size: 14px;
}

.react-datepicker__close-icon {
  right: 55px !important;
}

.react-datepicker__close-icon::after {
  background-color: #dc3545 !important;
}

.input__header--popup {
  color: #000000;
}

.btn-popup {
  background: #ebebea !important;
  border: none !important;
  padding: 7px 12px !important;
}

td.expense-plan__td .inputfile-wrap {
  align-items: center !important;
}

.expense-plan__td.expense-plan__td--icon {
  position: relative;
}

.expense-plan__td.expense-plan__td--icon img {
  position: absolute;
  width: 14px;
}

.expense-plan__p {
  text-align: left;
  white-space: pre-line;
  margin: 0;
  padding: 15px;
}

.success {
  position: absolute;
  background: #0f5151;
}

.Toastify__toast--success {
  text-align: center;
  color: #155724;
  background-color: #d4edda !important;
  padding: 0.75rem 1.25rem !important;
  margin-bottom: 1rem !important;
  border: 1px solid #c3e6cb !important;
  border-radius: 0.25rem !important;
}

.Toastify__toast--error {
  background: #f8d7da !important;
  text-align: center;
  color: #721c24;
  padding: 0.75rem 1.25rem !important;
  margin-bottom: 1rem !important;
  border: 1px solid #f5c6cb !important;
  border-radius: 0.25rem !important;
}

.Toastify__toast--warning {
  background: #fff3cd !important;
  text-align: center;
  color: #856404;
  padding: 0.75rem 1.25rem !important;
  margin-bottom: 1rem !important;
  border: 1px solid #ffeeba !important;
  border-radius: 0.25rem !important;
}

.Toastify__close-button {
  visibility: hidden !important;
}

@media (max-width: 768px) {
  .btn-mob {
    /*pointer-events: none;*/
    opacity: 0.7;
    cursor: cell !important;
  }

  .competition__right {
    cursor: default !important;
  }
}

.btn-policy {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.btn-policy:hover {
  opacity: 1;
}

.btn-policy:before,
.btn-policy:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 16px;
  width: 2px;
  background-color: #333;
}

.btn-policy:before {
  transform: rotate(45deg);
}

.btn-policy:after {
  transform: rotate(-45deg);
}

.policy-text {
  overflow: scroll;
  height: 500px;
  font-size: 14px;
  padding-right: 15px;
  margin-bottom: 10px;
}
.policy-text p {
  margin: 15px 0 0;
  font-weight: bold;
}

.policy-header {
  font-size: 12px;
  margin-bottom: 15px;
  cursor: pointer;
  text-decoration: underline;
  text-align: left;
}

.policy-header:hover {
  color: #007e6d;
}

.policy-modal ~ .popup-content {
  max-width: 900px !important;
}

.home__calendar {
  display: flex;
  align-items: center;
}

@media (max-width: 470px) {
  .home__calendar {
    flex-direction: column;
    align-items: flex-start;
  }
}

.calendar {
  position: relative;
  width: 100%;
  max-width: 250px;
  display: flex;
}

@media screen and (max-width: 992px) {
  .calendar .example-custom-input {
    font-size: 10px;
    padding: 0 0 0 5px;
  }
}
.calendar__icon {
  position: absolute;
  right: 10px;
  top: 8px;
}

.input__cal {
  margin-right: 15px;
}

.react-datepicker__day--selected {
  background-color: #de944c !important;
}

.react-datepicker__month-select,
.react-datepicker__year-select {
  background-color: #007e6d !important;
  border: none;
  color: #ffffff;
  outline: none;
}

.react-datepicker__year-dropdown-container.react-datepicker__year-dropdown-container--select {
  outline: none;
}

.inputfile-wrap__download {
  color: #000000;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  padding: 5px 0 0;
}

.inputfile-wrap__download a {
  padding: 5px 0 0;
}

.logo-partner {
  margin-left: 30px;
  max-width: 170px;
  width: 100%;
}

@media (max-width: 450px) {
  .logo-partner {
    margin-left: 10px;
    max-width: 156px;
  }
}

@media (max-width: 410px) {
  .logo-partner {
    max-width: 100px;
  }
}

.progress-page {
  position: fixed;
  width: 100%;
  z-index: 1;
  left: 0;
  top: 0;
  border-radius: 0 !important;
  height: 7px !important;
  font-size: 10px !important;
}

.progress-page .progress-bar {
  background-color: #007e6d;
}

.budget-count {
  font-size: 14px;
}

.budget-error {
  font-size: 12px;
}

.label-required {
  color: #dc3545;
}

.op-file {
  justify-content: start;
  flex-direction: row;
  align-items: center;
  padding-top: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #212121;
}

.text__success {
  color: #007e6d;
}

.icon-delete {
  cursor: pointer;
  background-color: #dc3545;
  color: #fff;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  min-height: 16px;
  min-width: 16px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(45deg);
}
.top-right {
  position: absolute;
  bottom: 80%;
  right: -5px;
}

@media (max-width: 600px) {
}
