.extra-tr {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}
.extra-tr > * {
  padding-top: 10px;
}
.extra-label {
  font-size: 16px;
  padding-top: 10px;
}
.extra-label_budget {
  font-size: 12px;
  margin-bottom: 10px;
  color: #444444;
}
.extra-label_budget > br {
  /*display: block;*/
  /*margin: 20px;*/
  /*content: " ";*/
}
textarea {
  width: 1000px;
  font-size: 16px;
  font-family: 'Roboto', Sans-serif;
}
.extra-h1 {
  padding-top: 20px;
  font-size: 18px;
}
.main-label {
  font-weight: bold;
}
