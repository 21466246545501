.messages__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 450px;
  max-height: 450px;
  border-top: 1px solid #b7d1ff;
}
.messages__body::-webkit-scrollbar {
  display: none;
}
.messages__body {
  -ms-overflow-style: none;
  overflow-y: scroll;
}
.message {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  min-height: 149px;
  width: 100%;
  padding: 23px 13px 23px 0;
}
.message.from-manager {
  background: #f0f4fb;
  border: 1px solid #b7d1ff;
}
.message__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.message__header_left {
  display: flex;
  align-items: center;
}
.message__avatar {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: #f4f7f9;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0f5151;
}
.message__avatar_fsr {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: #0f5151;
  margin-left: 60px;
}
.message__responsible {
  margin-left: 23px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #212121;
}
.message__fsr {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #212121;
  margin-left: 7px;
}
.message__manager {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #8e8e8e;
  margin-left: 23px;
}
.message__body {
  margin-left: 68px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
  max-width: 514px;
}
.message__body_fsr {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #212121;
  margin-left: 104px;
}
.message__time {
  display: flex;
  justify-self: flex-end;
}
.messages__footer {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.messages__text {
  display: flex;
  width: 100%;
  height: 100px;
  margin-right: 13px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #212121;
  padding: 9px;
  border: 1px solid #b7d1ff;
  border-radius: 3px;
  resize: none;
  overflow: hidden;
}
.messages__text::placeholder {
  text-align: left;
  color: #909090;
}
.messages__button {
  width: 250px;
  height: 42px;
  background: #3770d4;
  border-radius: 3px;
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
}
