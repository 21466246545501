.info__box {
  padding: 20px;
  background-color: rgba(244, 244, 244, 0.5);
  border: 0 solid rgba(244, 244, 244, 0.5);
  border-radius: 10px;
}

.info__header {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: bold;
}

.info__description {
  margin: auto;
  font-size: 12px;
}

.info__instruction {
  font-size: 12px;
  font-weight: bold;
  margin-top: 5px;
}

.info__img {
  width: 12px;
  margin-right: 5px;
  margin-top: -3px;
}

a.info__link {
  text-decoration: none;
  color: #000000;
}

a.info__link:hover {
  color: #007e6d;
}
