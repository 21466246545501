.app-table__container {
  width: 758px;
}
.app-table {
  width: 100%;
  border: 1px solid #b7d1ff;
}
.app-table thead tr {
  background: #f0f4fb;
  border: 1px solid #b7d1ff;
  height: 50px;
}
.app-table tbody tr {
  border-bottom: 1px solid #b7d1ff;
}
.app-table th,
.app-table td {
  height: 50px;
  vertical-align: middle;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #212121;
  text-align: left;
  padding: 0 13px;
}

.app-table th {
  font-weight: 500;
}
.app-table td a {
  text-decoration: none;
  color: #212121;
  font-size: 16px;
  line-height: 19px;
}
