.footer {
  background-color: #007e6d;
  color: #fff;
}

.footer__content {
  display: flex;
  justify-content: center;
  padding: 60px 0 60px 0;
}

@media (max-width: 768px) {
  .footer__content {
    padding: 60px 12px 60px 12px !important;
  }
}

.logowhite {
  width: 100px;
  margin: auto;
}

.footer__item {
  display: flex;
  flex-direction: column;
}

.footer__title {
  font-size: 16px;
  font-weight: bold;
  margin: 20px auto;
}

@media (max-width: 768px) {
  .footer__title {
    font-size: 14px;
    margin-top: 40px;
    text-align: center;
  }
}

.footer__text__title {
  font-size: 14px;
  font-weight: bold;
  margin: auto;
}

@media (max-width: 768px) {
  .footer__text__title {
    font-size: 12px;
  }
}

.footer__text__title2 {
  font-size: 14px;
  font-weight: bold;
  margin: auto;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .footer__text__title2 {
    font-size: 12px;
    margin-top: 10px;
  }
}

.footer__text__portal {
  color: #ebebeb;
  font-size: 14px;
  margin: auto;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .footer__text__portal {
    font-size: 12px;
    text-align: center;
    margin-top: 10px;
    line-height: 1.25em;
  }
}

.footer__text {
  font-size: 14px;
  margin: auto;
}

.footer__text a {
  color: #ffffff;
  text-decoration: none;
}

.footer__text a:hover {
  color: #de944c;
}

@media (max-width: 768px) {
  .footer__text {
    font-size: 12px;
  }
}
