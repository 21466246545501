/* =============== */
.body__header {
  font-family: 'Roboto', sans-serif;
  width: 100%;
  background-color: #fff !important;
  box-shadow: 0 6px 8px 0 rgb(0 0 0 / 10%);
}

.wrapper {
  max-width: 1000px;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 0;
}

.header {
  background-color: #fff;
  position: fixed;
  padding: 20px 0 20px 0;
  display: flex;
}

.bg-light {
  --bs-bg-opacity: 1;
  padding: 20px 0 20px 0;
}

.Logoheader {
  width: 86.8px;
}

.navbar {
  padding: 20px 0 !important;
}

.header__username {
  font-size: 14px;
  line-height: 14px;
  color: #212121;
}

.header__username a {
  text-transform: capitalize;
  margin-left: 3px;
  text-decoration: none;
  color: #000000;
}

.navbar-nav .dropdown-menu {
  position: absolute !important;
  padding: 0 !important;
}

.dropdown-menu.show {
  background-color: #007e6d;
  color: #ffffff;
  border-radius: 0;
  border: none !important;
  padding: 0;
}

.dropdown-item {
  color: #ffffff !important;
  padding: 10px 1rem !important;
}

.dropdown-item:active,
.dropdown-item:hover {
  background: #de944c !important;
}

.links-desc {
  display: none;
}
.body__header--fix {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
}

@media (max-width: 991px) {
  .Logoheader {
    width: 80px;
  }

  .links-mob {
    display: none;
  }

  .visible .navbar-toggler {
    display: none;
  }

  .header__links--mob.links-desc {
    display: block;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .body__header--fix {
    position: fixed;
    top: 0;

    z-index: 1;
  }
  .navbar-toggler {
    margin-left: auto;
  }

  .navbar-nav {
    align-items: flex-start !important;
  }

  .nav-header > div {
    padding-left: 0 !important;
  }

  .nav-header,
  .header-menu__logout {
    margin: 0 0 0 10px !important;
  }

  .nav-link {
    padding: 0 !important;
  }

  .header__links--mob {
    padding: 0.5rem 0 !important;
    letter-spacing: 0.3px;
  }

  .navbar-toggler {
    position: relative;
  }

  .navbar-toggler .navbar-toggler-icon {
    visibility: hidden;
  }

  .navbar-toggler.collapsed .navbar-toggler-icon {
    visibility: visible;
  }

  .navbar-toggler.collapsed .navbar-toggler-icon {
    width: 30px;
    height: 3px;
    background-color: #007e6d;
    border-radius: 5px;
    background-image: none;
  }

  .navbar-toggler.collapsed:before {
    top: -3px;
    transform: rotate(0deg);
  }

  .navbar-toggler.collapsed:after {
    top: 11px;
    transform: rotate(0deg);
  }

  .navbar-toggler.collapsed:before,
  .navbar-toggler.collapsed:after {
    content: '';
    position: absolute;
    width: 30px;
    height: 3px;
    background: #007e6d;
    border-radius: 5px;
    left: 12px;
  }

  .navbar-toggler:before,
  .navbar-toggler:after {
    content: '';
    position: absolute;
    width: 30px;
    height: 3px;
    background: #007e6d;
    border-radius: 5px;
    left: 10px;
    top: 16px;
  }

  .navbar-toggler:before {
    transform: rotate(45deg);
  }

  .navbar-toggler:after {
    transform: rotate(-45deg);
  }

  .header__username {
    font-size: 12px;
  }

  .dropdown-menu[data-bs-popper] {
    top: 90% !important;
    left: -2px !important;
  }
}

#nav__end__flex {
  display: flex;
  margin-right: -7px;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
  margin-right: 20px;
}

.navbar-light .navbar-nav .nav-link {
  color: #000;
}

.navbar-light .navbar-nav .nav-link:focus {
  /*color: #DE944C !important;*/
}

#nav__end__flex {
  display: flex;
}

#SignOut__section {
  cursor: pointer;
}

.SignOut {
  margin-top: 8px;
}

.header__section2 {
  --bs-bg-opacity: 1;
  padding: 20px 0 20px 0;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  width: 100%;
}

@media (max-width: 991px) {
  .header__section2 {
    flex-wrap: wrap;
    padding: 10px 0 10px 0;
    margin-top: 0;
    background-color: #ececec;
  }
}

.header__divider {
  margin: 0 0 0 0;
  height: 1px;
  width: 100%;
  border-top: 1px solid #a8a8a8;
}

@media (max-width: 768px) {
  .header__divider {
    border: none;
  }
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 0 !important;
  list-style: none;
}

.header__breadcrumbs {
  margin-right: auto;
}

@media (max-width: 991px) {
  .header__breadcrumbs {
    margin: auto;
    margin-bottom: 10px;
  }
}

.breadcrumbs__header {
  font-size: 12px;
}

@media (max-width: 991px) {
  .breadcrumbs__header {
    font-size: 10px;
  }
}

.breadcrumb-item a {
  color: #000000 !important;
}

.breadcrumb-item::before {
  background: none !important;
}

.dropdown-toggle::after {
  background: none !important;
  border: none !important;
}

.header__name {
  justify-content: right;
  display: flex;
  padding: 0;
  cursor: pointer;
}

@media (max-width: 991px) {
  .header__name {
    width: 100%;
    padding: 0 10px 0 10px;
  }
}

.authorized__name__content {
  display: flex;
  margin-left: auto;
  padding: 0;
}

@media (max-width: 991px) {
  .authorized__name__content {
    margin: auto;
  }
}

.applicant__type {
  font-weight: bold;
  font-size: 14px;
  padding-right: 5px;
  margin: 0;
}

@media (max-width: 991px) {
  .applicant__type {
    font-size: 12px;
  }
}

.authorized__name1 {
  position: relative;
  margin: 0;
  font-size: 14px;
  padding: 0;
}

@media (max-width: 991px) {
  .authorized__name1 {
    font-size: 12px;
  }
}

.authorized__name2 {
  position: relative;
  margin: 0;
  font-size: 14px;
  padding: 0 5px 0 5px;
}

@media (max-width: 991px) {
  .authorized__name2 {
    font-size: 12px;
  }
}

.authorized__name3 {
  position: relative;
  margin: 0;
  font-size: 14px;
  padding: 0;
}

@media (max-width: 991px) {
  .authorized__name3 {
    font-size: 12px;
  }
}

.navbar-brand {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap;
}

@media (max-width: 991px) {
  .navbar-brand {
    margin-left: 10px;
  }
}

.navbar-toggler {
  display: flex;
  align-items: center;
  font-size: 24px;
  line-height: 1;
  background-color: transparent;
  border: none !important;
}

.navbar-light .navbar-toggler-icon {
  font-size: 18px;
}

@media (max-width: 991px) {
  .navbar-toggler {
    margin-right: 8px;
  }
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

@media (max-width: 991px) {
  .navbar-collapse {
    margin: 10px;
  }
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.nav-link {
  font-weight: bold;
  display: block;
  /*padding: 0.5rem 1rem;*/
  color: black;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
  text-transform: uppercase;
  letter-spacing: 0.3px;
}

.nav-link:focus {
  color: #000;
}

li:focus {
  color: #000;
}

#nav-dropdown:focus {
  color: #000;
}

.nav-link:hover,
.nav-link:focus {
  /*color: #DE944C !important;*/
  /*background: none !important;*/
  border: none !important;
}

/* new_design */
.nav-header,
.header-menu__logout {
  margin: 0 0 0 2rem;
  font-weight: bold !important;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #000000;
  text-transform: uppercase !important;
  cursor: pointer;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.nav-header > div:hover,
.nav-header > div:focus,
.mdc-tab > div:hover,
.mdc-tab > div:focus,
.header-menu__logout:hover,
.header-menu__logout:focus {
  /*color: #DE944C !important;*/
  /*background: none !important;*/
  border: none !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.mdc-tab--active:hover,
.mdc-tab--active:focus {
  /*color: #DE944C !important;*/
  /*background: none !important;*/
  border: none !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.nav-header > div {
  padding-left: 1rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.mdc-tab {
  letter-spacing: 0.3px !important;
  text-transform: uppercase !important;
  color: #000000;
}

#basic-nav-dropdown {
  padding: 0;
  color: #000000;
  font-weight: bold;
}

.dropdown-item.active {
  background: #de944c !important;
}

/* new_design */
/* ============= */

.logo {
  height: 63px;
}

a,
a:active,
a:focus {
  outline: none; /* Works in Firefox, Chrome, IE8 and above */
}

.header__links {
  display: flex;
  font-weight: bold !important;
  color: #000000 !important;
}

.header-menu__logout:hover,
.nav-link:hover,
.header__links:hover {
  color: #de944c !important;
}

.header__languages {
  display: flex;
  justify-content: space-between;
}

.header__language {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #212121;
  cursor: pointer;
}

.header__language.active {
  color: #209898;
}

.header__name {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #0f5151;
  cursor: pointer;
}

a.header__link {
  text-decoration: none;
  color: #000000;
}

.header__username a:hover,
a.header__link:hover {
  color: #de944c;
}

.header__browser {
  display: none;
}

@media (min-width: 992px) {
  .header__browser {
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    width: 100%;
    padding-left: 40px;
  }

  .header__browser--show {
    display: block;
  }

  .header__browser.header__browser--authenticated {
    padding-left: 7px;
    width: 32%;
  }
}

@media (min-width: 1200px) {
  .header__browser.header__browser--authenticated {
    padding-left: 0;
    width: 47%;
  }
}

@media (min-width: 1400px) {
  .header__browser.header__browser--authenticated {
    padding-left: 0;
    width: 55%;
  }
}
